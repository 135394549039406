import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import TextInput from './TextInput'
import Button from "./Button";
import AOS from "aos";
import Success from "../animations/Success";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.form = React.createRef();
        this.state = {
            status: "",
            animation: "none",
            destructMessage: true
        };
    }

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top",
            }
        )
    }

    submitForm(ev) {
        this.setState({animation: "block", status: ""});

        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({status: "SUCCESS"});

            } else {
                this.setState({status: "ERROR"});
            }
        };
        xhr.send(data);
        setTimeout(() => {
            this.setState({animation: "none"})
        }, 3000)
    }

    render() {
        const {t} = this.props;
        const {status} = this.state;

        return (
            <div className="section-wrapper contact">
                <div className="section-text">
                    <p data-aos="fade-right" data-aos-delay="200"
                       className="section-header">{t(`contact.header`)}</p>
                    <h2 data-aos="fade-right" data-aos-delay="300">{t(`contact.title`)}</h2>
                    <p data-aos="fade-right-custom" data-aos-delay="400">{t(`contact.text`)}</p>
                    <form
                        ref={this.form}
                        onSubmit={this.submitForm}
                        action="https://formspree.io/xnqvpaoe"
                        method="POST"
                        className=" ">
                        <TextInput type="text" name="name" animation="fade-right-custom" delay="500" text
                                   placeholder="Full name" label="Full name"/>
                        <TextInput type="email" name="email" animation="fade-right-custom" delay="600" text
                                   placeholder="Email" label="Email"/>
                        <TextInput type="text" name="message" animation="fade-right-custom" delay="700"
                                   placeholder="Enter your message here..." label="Message"/>
                        <Button type="submit"/>
                        {status === "ERROR" ?
                            <p style={{color: "red"}}>Ooops! There was an error. Please try again.</p> : ""}
                    </form>
                </div>
                <Success display={this.state.animation} play={status === "SUCCESS"}/>
                <div className="contact-map-wrapper">
                    <iframe title="Map De Kleine Aapjes" className="contact-map"
                            src="https://maps.google.com/maps?width=100%&height=600&hl=nl&q=Osloweg%2011b+(De%20Kleine%20Aapjes)&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        <a href="https://www.mapsdirections.info/nl/maak-een-google-map/"> </a>
                    </iframe>
                </div>
                <br/>
            </div>
        )
    }
}

export default translate(Contact)
