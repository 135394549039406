import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/images/logo/monkey-cropped.png'

class Header extends Component {

	constructor (props) {
		super(props)
		this.state = {
			mobileHeader: false,
			menuOpen: false,
			about: false,
			daycare: false,
		}
		this.header = React.createRef()
		this.headerContainer = React.createRef()
	}

	scroll = () => {
		let currentScrollPos = window.pageYOffset

		if (currentScrollPos < 50) {
			this.headerContainer.current.style.backgroundColor = 'transparent'
			this.headerContainer.current.style.boxShadow = 'none'
		} else if (currentScrollPos > 50) {
			this.headerContainer.current.style.boxShadow = ' 0px 4px 11px -8px #D9D9D9'
			this.headerContainer.current.style.backgroundColor = '#fefefe'
		}
	}

	openMenu = () => {
		this.setState({ menuOpen: !this.state.menuOpen }, () => {
		})
	}

	closeMenu = () => {
		this.setState({ menuOpen: !this.state.menuOpen }, () => {
		})
	}

	openSubMenu = (menu) => {
		this.setState({ [menu]: !this.state[menu] })
	}

	componentDidMount () {
		window.addEventListener('scroll', this.scroll)
	}

	componentWillUnmount () {
		window.removeEventListener('scroll', this.scroll)
	}

	render () {

		const { t } = this.props
		return (
			<div ref={this.headerContainer} className="header-container">
				<div className="header-logo">
					<NavLink exact to="/">
						<img alt={'De Kleine Aapjes logo'} src={logo}/>
					</NavLink>
				</div>
				<div onClick={this.openMenu} className={'header-hamburger'}>
					<div className={this.state.menuOpen ? 'nav-icon open' : 'nav-icon '}>
					</div>
				</div>

				<ul className={this.state.menuOpen ? 'header-menu open' : 'header-menu'}>
					<li>
						<NavLink onClick={this.closeMenu} activeClassName="active-link" exact to={'/'}>Home</NavLink>
					</li>

					<li className={this.state.about ? 'open' : ''} onClick={() => {this.openSubMenu('about')}}>
						<NavLink activeClassName="active-link" to={'/over'}>{t('header.about.title')}
							<FontAwesomeIcon icon={faChevronUp}/>
						</NavLink>
						<ul className="dropdown">
							<li onClick={this.closeMenu}><NavLink activeClassName='active-link-open' smooth
																  to={'/over#locatie'}>{t(
								'header.about.sub.1')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink activeClassName='active-link-open' smooth
																  to={'/over#opening'}>{t(
								'header.about.sub.2')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink activeClassName='active-link-open' smooth
																  to={'/over#voorwaarden'}>{t(
								'header.about.sub.3')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink activeClassName='active-link-open' smooth
																  to={'/over#tarieven'}>{t(
								'header.about.sub.4')}</NavLink>
							</li>
						</ul>
					</li>

					<li className={this.state.daycare ? 'open' : ''} onClick={() => {this.openSubMenu('daycare')}}>
						<NavLink activeClassName='active-link' to={'/opvang'}>{t('header.daycare.title')}
							<FontAwesomeIcon icon={faChevronUp}/>
						</NavLink>
						<div className="dropdown">
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#dagen'}>{t(
								'header.daycare.sub.1')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#peuterspeelzaal'}>{t(
								'header.daycare.sub.2')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#bso'}>{t(
								'header.daycare.sub.3')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#vso'}>{t(
								'header.daycare.sub.4')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#beleid'}>{t(
								'header.daycare.sub.5')}</NavLink>
							</li>
							<li onClick={this.closeMenu}><NavLink exact activeClassName='active-link-open' smooth
																  to={'/opvang/#camera'}>{t(
								'header.daycare.sub.6')}</NavLink>
							</li>
						</div>
					</li>
					<li><NavLink exact onClick={this.closeMenu} activeClassName='active-link-open'
								 to={'/bitcare'}>{t('header.bitcare')}</NavLink></li>
					<li><NavLink exact onClick={this.closeMenu} activeClassName='active-link-open'
								 to={'/gallerij'}>{t('header.gallery')}</NavLink></li>
					<li><NavLink exact onClick={this.closeMenu} activeClassName='active-link-open'
								 to={'/contact'}>{t('header.contact')}</NavLink></li>
					<div className="signup-button"><NavLink exact onClick={this.closeMenu}
															to={'/inschrijven'}>{t('header.inschrijven')}</NavLink></div>
				</ul>
			</div>
		)
	}
}

export default translate(Header)
