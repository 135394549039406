import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import AOS from 'aos'

class Section extends Component {

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top",
            }
        );
    }

    renderParagraphs = (animation) => {
        const breakWord = 'AlineaBreak';
        const {t, text} = this.props;
        const pars = [];
        const Paragraphs = t(`${text}`);
        const Array = Paragraphs.split(breakWord);
        for (let i = 0; i < Array.length; i++) {
            pars.push(
                <React.Fragment>
                    <p data-aos={`fade-${animation}-custom`} data-aos-delay="500" data-aos-easing="ease-in-out"
                       data-aos-anchor-placement="top"
                       data-aos-duration="600">{Array[i]}</p>
                    <br/>
                </React.Fragment>,
            )
        }
        return pars
    };


    render() {
        const {t, image, shape, reverse} = this.props;
        return (
            <div style={this.props.style} className="section-wrapper">

                {reverse ? (<React.Fragment>
                            <div style={{justifyContent: "flex-start"}} className="section-image-wrapper pc">
                                <div className="section-image" data-aos="fade-right" data-aos-delay="400"
                                     data-aos-anchor-placement="center" style={{backgroundImage: `url(${image})`}}>
                                </div>
                                {shape ?
                                    (<img className="section-shape left" alt="" src={shape.shape_1.image}/>
                                    ) :
                                    null
                                }
                            </div>
                            <div style={{alignItems: "flex-end"}} className="section-text">
                                <p data-aos="fade-left-pc" data-aos-delay="100"
                                   className="section-header">{t(`${this.props.header}`)}</p>
                                <h2 data-aos="fade-left-pc" data-aos-delay="300">{t(`${this.props.title}`)}</h2>
                                {this.renderParagraphs("left")}
                            </div>
                            <div style={{justifyContent: "flex-start"}} className="section-image-wrapper mobile">
                                <div className="section-image" data-aos="fade-right-custom" data-aos-delay="400"
                                     data-aos-anchor-placement="center"
                                     style={{backgroundImage: `url(${image})`}}></div>
                                {shape ?
                                    (<img className="section-shape left" alt="" src={shape.shape_1.image}/>
                                    ) :
                                    null
                                }
                            </div>
                        </React.Fragment>
                    )
                    :
                    (
                        <React.Fragment>
                            <div style={{justifyContent: "flex-start"}} className="section-text">
                                <p data-aos="fade-right" data-aos-delay="100"
                                   className="section-header">{t(`${this.props.header}`)}</p>
                                <h2 data-aos="fade-right" data-aos-delay="300">{t(`${this.props.title}`)}</h2>
                                {this.renderParagraphs("right")}
                            </div>
                            <div style={{justifyContent: "flex-end"}} className="section-image-wrapper">
                                <div className="section-image" data-aos="fade-left-custom" data-aos-delay="400"
                                     data-aos-anchor-placement="center" style={{backgroundImage: `url(${image})`}}>
                                </div>
                                {shape ?
                                    (<img className="section-shape right" alt="" src={shape.shape_1.image}/>
                                    ) :
                                    null
                                }
                            </div>
                        </React.Fragment>
                    )}
            </div>
        )
    }
}

export default translate(Section)
