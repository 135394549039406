import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import AOS from 'aos'

class OpeningHours extends Component {

	componentDidMount () {
		AOS.init()
	}

	render () {
		const { t } = this.props
		return (
			<React.Fragment>
				{/*//Opening hours / Closing days*/}
				<p id="opening" data-aos="fade-right" data-aos-delay="100" data-aos-easing="ease-in-out"
				   data-aos-anchor-placement="top"
				   data-aos-duration="600"
				   className="page-header">{t(`about.hours.header`)}</p>

				<h2 data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out"
					data-aos-anchor-placement="top"
					data-aos-duration="600">{t(`about.hours.title`)}</h2>

				<div className="page-bullets">
					<div data-aos="fade-up" data-aos-delay="400"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.open.preschool.title`)}</h3>
						<li>{t(`about.hours.open.preschool.monday`)}</li>
						<li>{t(`about.hours.open.preschool.tuesday`)}</li>
						<li>{t(`about.hours.open.preschool.wednesday`)}</li>
						<li>{t(`about.hours.open.preschool.thursday`)}</li>
						<li>{t(`about.hours.open.preschool.friday`)}</li>
					</div>

					<div data-aos="fade-up" data-aos-delay="450"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.open.afterschool.title`)}</h3>
						<li>{t(`about.hours.open.afterschool.monday`)}</li>
						<li>{t(`about.hours.open.afterschool.tuesday`)}</li>
						<li>{t(`about.hours.open.afterschool.wednesday`)}</li>
						<li>{t(`about.hours.open.afterschool.thursday`)}</li>
						<li>{t(`about.hours.open.afterschool.friday`)}</li>
					</div>

					<div data-aos="fade-up" data-aos-delay="500"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.open.daycare.title`)}</h3>
						<li>{t(`about.hours.open.daycare.monday`)}</li>
						<li>{t(`about.hours.open.daycare.tuesday`)}</li>
						<li>{t(`about.hours.open.daycare.wednesday`)}</li>
						<li>{t(`about.hours.open.daycare.thursday`)}</li>
						<li>{t(`about.hours.open.daycare.friday`)}</li>
					</div>

					<div data-aos="fade-up" data-aos-delay="550"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.open.kindergarten.title`)}</h3>
						<li>{t(`about.hours.open.kindergarten.monday`)}</li>
						<li>{t(`about.hours.open.kindergarten.tuesday`)}</li>
						<li>{t(`about.hours.open.kindergarten.wednesday`)}</li>
						<li>{t(`about.hours.open.kindergarten.thursday`)}</li>
						<li>{t(`about.hours.open.kindergarten.friday`)}</li>
					</div>

					<div data-aos="fade-up" data-aos-delay="600"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.closed.closed_days.title`)}</h3>
						<li>{t(`about.hours.closed.closed_days.closed1`)}</li>
						<li>{t(`about.hours.closed.closed_days.closed2`)}</li>
						<li>{t(`about.hours.closed.closed_days.closed3`)}</li>
						<li>{t(`about.hours.closed.closed_days.closed4`)}</li>
						<li>{t(`about.hours.closed.closed_days.closed5`)}</li>
						<li>{t(`about.hours.closed.closed_days.closed6`)}</li>
					</div>

					<div data-aos="fade-up" data-aos-delay="650"
						 data-aos-easing="ease-in-out"
						 data-aos-anchor-placement="center"
						 data-aos-duration="600">
						<h3>{t(`about.hours.closed.closed_earlier.title`)}</h3>
						<li>{t(`about.hours.closed.closed_earlier.closed1`)}</li>
						<li>{t(`about.hours.closed.closed_earlier.closed2`)}</li>
						<li>{t(`about.hours.closed.closed_earlier.closed3`)}</li>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default translate(OpeningHours)
