import React, {Component} from 'react'
import AboutPage from '../components/about/AboutPage'

class About extends Component {
	render () {
		return (
			<div className="general-div padding">
				<AboutPage/>
			</div>
		)
	}
}

export default About
