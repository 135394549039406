import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import AOS from 'aos'
import image from '../../assets/images/background/safety.jpg'
import bitcare from '../../assets/images/background/bitcare.png'

class BitcareText extends Component {

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top",
            }
        )
    }

    renderParagraphs = (text, delay) => {
        const breakWord = 'AlineaBreak'
        const pars = []
        const Array = text.split(breakWord)
        for (let i = 0; i < Array.length; i++) {
            pars.push(
                <React.Fragment>
                    <p data-aos="fade-right-custom" data-aos-delay={delay} data-aos-easing="ease-in-out"
                       data-aos-anchor-placement="bottom"
                       data-aos-duration="600">{Array[i]}</p>
                    <br/>
                </React.Fragment>,
            )
        }
        return pars
    }

    render() {
        const {t} = this.props
        return (
            <React.Fragment>
                <div className="page-wrapper">
                    <div className="page-text">
                        <p data-aos="fade-right" data-aos-delay="100"
                           className="page-header">{t(`bitcare.header`)}</p>
                        <h2 data-aos="fade-right" data-aos-delay="300">{t(`bitcare.section_1.title`)}</h2>
                        {this.renderParagraphs(t(`bitcare.section_1.text`, 500))}
                            <h2 data-aos="fade-right" data-aos-delay="500">{t(`bitcare.section_2.title`)}</h2>
                            {this.renderParagraphs(t(`bitcare.section_2.text`, 800))}
                    </div>
                    <div className="page-image-wrapper">
                        <div className="page-image" data-aos="fade-left-custom" data-aos-delay="400"
                             data-aos-anchor-placement="center" style={{backgroundImage: `url(${image})`}}>
                        </div>
                    </div>
                </div>
                <div className="page-wrapper">
                    <img className="bitcare" alt="child-happy"  src={bitcare}/>
                </div>
            </React.Fragment>
        )
    }
}

export default translate(BitcareText)
