import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import AOS from 'aos'
import image from '../../assets/images/background/kid-smiling.jpg'

class Location extends Component {

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top",
            }
        )
    }

    renderParagraphs = (text) => {
        const breakWord = 'AlineaBreak'
        const pars = []
        const Array = text.split(breakWord)
        for (let i = 0; i < Array.length; i++) {
            pars.push(
                <React.Fragment>
                    <p data-aos="fade-right-custom" data-aos-delay="500" data-aos-easing="ease-in-out"
                       data-aos-anchor-placement="bottom">{Array[i]}</p>
                    <br/>
                </React.Fragment>,
            )
        }
        return pars
    };

    render() {
        const {t} = this.props
        return (
            <React.Fragment>
                <div id="locatie" className="page-wrapper">
                    <div className="page-text">
                        <p  data-aos="fade-right" data-aos-delay="100"
                           className="page-header">{t(`about.location.header`)}</p>
                        <h2 data-aos="fade-right" data-aos-delay="300">{t(`about.location.title`)}</h2>
                        {this.renderParagraphs(t(`about.location.text`))}
                    </div>
                    <div className="page-image-wrapper">
                        <div className="page-image" data-aos="fade-left-custom" data-aos-delay="400"
                             data-aos-anchor-placement="center" style={{backgroundImage: `url(${image})`}}>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate(Location)
