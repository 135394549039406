import React, {Component} from 'react'
import BitcarePage from "../components/bitcare/BitcarePage";

class BitCare extends Component {
    render () {
        return (
            <div className="general-div padding">
                <BitcarePage/>
            </div>
        )
    }
}

export default BitCare
