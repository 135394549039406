import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import OpeningHours from './OpeningHours'
import Location from './Location'
import Terms from './Terms'
// import PinAnimation from "../animations/PinAnimation";

class AboutPage extends Component {

    render() {
        return (
            <div style={this.props.style} className="page-container">
                {/*<PinAnimation/>*/}
				<Location/>
                <OpeningHours/>
                <Terms/>
            </div>
        )
    }
}

export default translate(AboutPage)
