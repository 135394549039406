import React, {Component} from 'react'
import {translate} from 'react-multi-lang'

class SignUp extends Component {
    render() {
		const {t} = this.props;
		return (
            <div className="general-div padding">
                <div className="page-wrapper">
                    <div className="page-text">
                        <p data-aos="fade-right" data-aos-delay="100"
                           className="page-header">{t(`sign_up.header`)}</p>
                        <h2 data-aos="fade-right" data-aos-delay="300">{t(`sign_up.title`)}</h2>
                        <iframe className="sign-up-container"
                                src="https://app.bitcare.com/organization-form/BUB4QFkVpgf8" title="sign-up">
                        </iframe>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate(SignUp)
