import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import BitcareText from "./BitcareText";

class BitcarePage extends Component {

    render() {
        return (
            <div style={this.props.style} className="page-container">
                <BitcareText/>
            </div>
        )
    }
}

export default translate(BitcarePage)
