import React, {useEffect} from 'react';
import {translate} from 'react-multi-lang'
import AOS from "aos";

const Button = (props) => {

    useEffect(() => {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top-top",
            }
        )
    });

    const {t, animation, delay, onClick, type} = props;
    return (
        <button  type={type} onClick={onClick} data-aos={animation} data-aos-once="true"
                data-aos-delay={delay} className="custom-button">
            {t("contact.button")}
        </button>
    );
};

export default translate(Button);