import React, {Component} from 'react'
import OpvangPage from "../components/opvang/OpvangPage";

class Opvang extends Component {
    render () {
        return (
            <div className="general-div padding">
                <OpvangPage/>
            </div>
        )
    }
}

export default Opvang
