import React from 'react';
import {images} from "./Images";
import Gallery from 'react-grid-gallery';

function GalleryImages(props) {

    // const imageSize = (width, height,  newWidth) => {
    //     const newHeight = (height/width) * newWidth;
    //     alert(newHeight);
    // };

    return (
        <div className="gallery-container">
            <Gallery backdropClosesModal images={images}/>
            {/*{console.log(imageSize(687, 632, 312))}*/}
        </div>
    );
}

export default GalleryImages;