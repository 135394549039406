import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import AOS from 'aos'
// import camera from "../assets/animations/camera";
// import Animation from "../components/animations/Animation";
import GalleryImages from "../components/gallery/GalleryImages";


class Gallery extends Component {

    componentDidMount () {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement:"top",
            }
        )
    }

    render () {
        const { t } = this.props
        return (
            <div className="general-div padding">
                <div className="page-container">
                    {/*<Animation animation={camera}/>*/}
                    <div className="page-text">
                        <p data-aos="fade-right-custom" data-aos-delay="100"className="page-header">{t(`gallery.header`)}</p>
                        <h2 data-aos="fade-right-custom" data-aos-delay="300">{t(`gallery.title`)}</h2>

                        <p data-aos="fade-right-custom" data-aos-delay="500" data-aos-easing="ease-in-out"
                           data-aos-anchor-placement="top"
                           data-aos-duration="600">{t(`gallery.text`)}</p>
                    </div>
                    <GalleryImages/>
                </div>

            </div>
        )
    }
}

export default translate(Gallery)
