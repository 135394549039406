import React from 'react'
import {translate} from 'react-multi-lang'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'
import file from "../../assets/files/Algemene-voorwaarden-voor-Kinderopvang-2017.pdf";
import file2 from "../../assets/files/Huisreglement-De-Kleine-Aapjes-2020.pdf";
function Terms (props) {
	const { t } = props;

	return (
		<div id="voorwaarden" className="page-regulations">
			<p  data-aos="fade-right" data-aos-delay="100" data-aos-easing="ease-in-out"
			   data-aos-anchor-placement="top"
			   data-aos-duration="600"
			   className="page-header">{t(`about.legal.header`)}</p>

			<h2 data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out"
				data-aos-anchor-placement="top"
				data-aos-duration="600">{t(`about.legal.title`)}</h2>

			<a data-aos="fade-up" data-aos-delay="600" data-aos-easing="ease-in-out"
			   data-aos-anchor-placement="top"
			   data-aos-duration="600" target="_blank"
			   href={file}
			   rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}/>
				{t(`about.legal.regulations`)}
			</a>

			<a data-aos="fade-up" data-aos-delay="800" data-aos-easing="ease-in-out"
			   data-aos-anchor-placement="top"
			   data-aos-duration="600" target="_blank"
			   href={file2}
			   rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}/>{t(`about.legal.terms`)}</a>
		</div>
	)
}

export default translate(Terms)
