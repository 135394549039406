import React from 'react'
import {translate} from 'react-multi-lang'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'
import file from "../../assets/files/Veiligheid-en-gezonheidsbeleid-December-2019.pdf";
import file2 from "../../assets/files/PB-DE-Kleine-Aapjes-december-2019.pdf";
function OpvangTerms (props) {
    const { t } = props;

    return (
        <div className="page-regulations margin-reverse">
            <p id="beleid" data-aos="fade-right" data-aos-delay="100" data-aos-easing="ease-in-out"
               data-aos-anchor-placement="top"
               data-aos-duration="600"
               className="page-header">{t(`opvang.beleid.legal.header`)}</p>

            <h2 data-aos="fade-right" data-aos-delay="300" data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top"
                data-aos-duration="600">{t(`opvang.beleid.title`)}</h2>

            <a data-aos="fade-up" data-aos-delay="600" data-aos-easing="ease-in-out"
               data-aos-anchor-placement="top"
               data-aos-duration="600" target="_blank"
               href={file}
               rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}/>
                {t(`opvang.beleid.protocol_1`)}
            </a>

            <a data-aos="fade-up" data-aos-delay="800" data-aos-easing="ease-in-out"
               data-aos-anchor-placement="top"
               data-aos-duration="600" target="_blank"
               href={file2}
               rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}/>{t(`opvang.beleid.protocol_2`)}</a>
        </div>
    )
}

export default translate(OpvangTerms)
