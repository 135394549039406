import React, { Component } from 'react'
import Landing from '../components/general/Landing'
import Section from '../components/general/Section'
import { translate } from 'react-multi-lang'

import vision from '../assets/images/background/hands.jpg'
import nurture from '../assets/images/background/playing.jpg'
import trust from '../assets/images/background/children-trust.jpg'

import triangle from '../assets/images/shapes/shape-triangle.svg'
import blob from '../assets/images/shapes/blob.svg'
import Contact from '../components/general/Contact'

class Home extends Component {
	render () {

		const shapes = {
			one: {
				shape_1: {
					image: triangle,
					style: { top: 0, left: '5%', width: '20vw' },
				},
			},
			two: {
				shape_1: {
					image: blob,
					style: { top: 0, right: '0%', width: '20vw' },
				},
			},
		}

		return (
			<div className="general-div">
				<Landing/>
				<Section image={trust} header="home.vision.trust.header" title="home.vision.trust.title"
						 text="home.vision.trust.text"/>
				<Section shape={shapes.two} header="home.vision.nurture.header" title="home.vision.nurture.title"
						 image={nurture} reverse text="home.vision.nurture.text"/>
				<Section shape={shapes.one} header="home.vision.vision.header" title="home.vision.vision.title"
						 text="home.vision.vision.text" image={vision}/>
				<Contact/>
			</div>
		)
	}
}

export default translate(Home)
