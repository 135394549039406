import React, {Component} from 'react';
import video from "../../assets/videos/de-kleine-aapjes.mp4";

class Landing extends Component {
    render() {
        return (
            <div className="landing-container" style={{backgroundImage:`url()`}}>
                <video muted autoPlay
                       typeof={'video/mp4'} loop
                       className="landing-video" playsinline>
                    <source src={video} type="video/mp4"/>
                </video>
                <div className="landing-slogan">
                    <h1>De Kleine Aapjes</h1>
                    <h2>De leukste opvang van nederland</h2>
                    {/*<img src={shape}/>*/}
                </div>
            </div>
        );
    }
}

export default Landing;
