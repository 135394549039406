import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import AOS from 'aos'
import file from "../../assets/files/Protocol-Cameratoezicht-De-Kleine-Aapjes.pdf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

class Camera extends Component {

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "top",
            }
        )
    }

    renderParagraphs = (text) => {
        const breakWord = 'AlineaBreak'
        const pars = []
        const Array = text.split(breakWord)
        for (let i = 0; i < Array.length; i++) {
            pars.push(
                <React.Fragment>
                    <p data-aos="fade-right-custom" data-aos-delay="500" data-aos-easing="ease-in-out"
                       data-aos-anchor-placement="bottom">{Array[i]}</p>
                    <br/>
                </React.Fragment>,
            )
        }
        return pars
    };

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className="page-wrapper">
                    <div className="page-text">
                        <p id="camera" data-aos="fade-right" data-aos-delay="100"
                           className="page-header">{t(`opvang.camera.header`)}</p>
                        {/*//Margedagen*/}
                        <h2 data-aos="fade-right" data-aos-delay="300">{t(`opvang.camera.title`)}</h2>
                        {this.renderParagraphs(t(`opvang.camera.text`))}
                        <a data-aos="fade-up" data-aos-delay="800" data-aos-easing="ease-in-out"
                           data-aos-anchor-placement="top"
                           data-aos-duration="600" target="_blank"
                           href={file}
                           rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf}/>{t(`opvang.beleid.protocol_2`)}</a>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate(Camera)
