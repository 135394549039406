import image from "../../assets/images/gallery/A.jpg"
import image2 from "../../assets/images/gallery/A2.jpg"
import image3 from "../../assets/images/gallery/B.jpg"
import image4 from "../../assets/images/gallery/B2.jpg"
import image5 from "../../assets/images/gallery/C.jpg"
import image6 from "../../assets/images/gallery/C2.jpg"
import image7 from "../../assets/images/gallery/D.jpg"
import image8 from "../../assets/images/gallery/D2.jpg"
import image9 from "../../assets/images/gallery/E.jpg"
import image10 from "../../assets/images/gallery/E2.jpg"
import image11 from "../../assets/images/gallery/F.jpg"
import image12 from "../../assets/images/gallery/F2.jpg"
import image13 from "../../assets/images/gallery/G.jpg"
import image14 from "../../assets/images/gallery/G2.jpg"
import image15 from "../../assets/images/gallery/H.jpg"
import image16 from "../../assets/images/gallery/H2.jpg"
import image17 from "../../assets/images/gallery/I.jpg"
import image18 from "../../assets/images/gallery/I2.jpg"
import image19 from "../../assets/images/gallery/J.jpg"
import image20 from "../../assets/images/gallery/J2.jpg"
import image21 from "../../assets/images/gallery/K.jpg"
import image22 from "../../assets/images/gallery/K2.jpg"
import image23 from "../../assets/images/gallery/L.jpg"
import image24 from "../../assets/images/gallery/L2.jpg"
import image25 from "../../assets/images/gallery/M.jpg"
import image26 from "../../assets/images/gallery/M2.jpg"
import image27 from "../../assets/images/gallery/N.jpg"
import image28 from "../../assets/images/gallery/N2.jpg"
import image29 from "../../assets/images/gallery/O.jpg"
import image30 from "../../assets/images/gallery/O2.jpeg"
import image31 from "../../assets/images/gallery/P.jpg"
import image32 from "../../assets/images/gallery/P2.jpeg"
import image33 from "../../assets/images/gallery/Q.jpg"
import image34 from "../../assets/images/gallery/R.jpg"
import image35 from "../../assets/images/gallery/S.jpg"
import image36 from "../../assets/images/gallery/T.jpg"
import image37 from "../../assets/images/gallery/U.jpg"
import image38 from "../../assets/images/gallery/V.jpg"
import image39 from "../../assets/images/gallery/W.jpg"
import image40 from "../../assets/images/gallery/X.jpg"
import image41 from "../../assets/images/gallery/Y.jpg"
import image42 from "../../assets/images/gallery/Z.jpg"



export const images = [

    {
        src: image,
        thumbnail: image,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },
    {
        src: image2,
        thumbnail: image2,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image3,
        thumbnail: image3,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },
    {
        src: image4,
        thumbnail: image4,
        thumbnailWidth: 312,
        thumbnailHeight: 317,
    },
    {
        src: image5,
        thumbnail: image5,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },{
        src: image6,
        thumbnail: image6,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image7,
        thumbnail: image7,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },
    {
        src: image8,
        thumbnail: image8,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image9,
        thumbnail: image9,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },
    {
        src: image10,
        thumbnail: image10,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },{
        src: image11,
        thumbnail: image11,
        thumbnailWidth: 312,
        thumbnailHeight: 175,
    },
    {
        src: image12,
        thumbnail: image12,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image13,
        thumbnail: image13,
        thumbnailWidth: 312,
        thumbnailHeight: 392,
    },
    {
        src: image14,
        thumbnail: image14,
        thumbnailWidth: 312,
        thumbnailHeight: 234,

    },
    {
        src: image15,
        thumbnail: image15,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },{
        src: image16,
        thumbnail: image16,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image17,
        thumbnail: image17,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image18,
        thumbnail: image18,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image19,
        thumbnail: image19,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image20,
        thumbnail: image20,
        thumbnailHeight: 312,
        thumbnailWidth: 175,
    }
    ,{
        src: image21,
        thumbnail: image21,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image22,
        thumbnail: image22,
        thumbnailWidth: 312,
        thumbnailHeight: 312,
    },
    {
        src: image23,
        thumbnail: image23,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image24,
        thumbnail: image24,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image25,
        thumbnail: image25,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },{
        src: image26,
        thumbnail: image26,
        thumbnailWidth: 312,
        thumbnailHeight: 312,
    },
    {
        src: image27,
        thumbnail: image27,
        thumbnailWidth: 312,
        thumbnailHeight: 507,
    },
    {
        src: image28,
        thumbnail: image28,
        thumbnailWidth: 312,
        thumbnailHeight: 220,
    },
    {
        src: image29,
        thumbnail: image29,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image30,
        thumbnail: image30,
        thumbnailWidth: 175,
        thumbnailHeight: 312,
    },{
        src: image31,
        thumbnail: image31,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image32,
        thumbnail: image32,
        thumbnailWidth: 175,
        thumbnailHeight: 312,
    },
    {
        src: image33,
        thumbnail: image33,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image34,
        thumbnail: image34,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image35,
        thumbnail: image35,
        thumbnailWidth: 312,
        thumbnailHeight: 362,
    },{
        src: image36,
        thumbnail: image36,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image37,
        thumbnail: image37,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image38,
        thumbnail: image38,
        thumbnailWidth: 312,
        thumbnailHeight: 287,
    },
    {
        src: image39,
        thumbnail: image39,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image40,
        thumbnail: image40,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },
    {
        src: image41,
        thumbnail: image41,
        thumbnailWidth: 312,
        thumbnailHeight: 234,
    },
    {
        src: image42,
        thumbnail: image42,
        thumbnailWidth: 312,
        thumbnailHeight: 416,
    },

];