import React from 'react'
import Lottie from 'react-lottie';
import animation from "../../assets/animations/success";

export default class Success extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isStopped: false, isPaused: false};
    }

    render() {

        const defaultOptions = {
            loop: false,
            autoplay: this.props.play,
            animationData: animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
            }
        };

        return <div className={`success-container ${this.props.display}`}>
            <Lottie options={defaultOptions}
                    width={250}
                    height={160}
                    margin={0}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
        </div>
    }
}
