import React, {Component} from 'react';
import AOS from "aos";

class TextInput extends Component {

    constructor(props) {
        super(props);
        this.placeholder = React.createRef();
    }

    renderState = (props) => {
        //Check if the state props exist and return it as string for className
        for (let prop in props) {
            switch (Object.prototype.hasOwnProperty.call(props, prop)) {
                case props.success:
                    return "success";
                case props.error :
                    return "error";
                case props.disabled :
                    return "disabled";
                default: return "asrr-input-label";
            }
        }
    };

    componentDidMount() {
        AOS.init(
            {
                offset: 200,
                duration: 600,
                easing: 'ease-in-out',
                placement: "center",
            }
        );
    }

    render() {
        const {placeholder, label, value, onChange, animation, delay, type, name} = this.props;

        return (
            <div data-aos-once="true"	 data-aos={animation} data-aos-delay={delay} className="asrr-input-container">
                {this.props.text ?
                    (<React.Fragment>
                            <label  className={`asrr-input-label ${this.renderState(this.props)}`}>{label}</label>
                            <input required={true} type={type} name={name} ref={this.placeholder} className={`asrr-input ${this.renderState(this.props)}`}
                                   value={value}
                                   onChange={onChange}
                                   onFocus={() => {
                                       this.placeholder.current.placeholder = " "
                                   }}
                                   onBlur={() => {
                                       this.placeholder.current.placeholder = placeholder
                                   }}
                                   placeholder={placeholder}/>
                        </React.Fragment>


                    ) :
                    (
                        <React.Fragment>
                            <label className={`asrr-input-label ${this.renderState(this.props)}`}>{label}</label>

                            <textarea required={true} type={type} name={name} ref={this.placeholder} className={`asrr-input ${this.renderState(this.props)}`}
                                      value={value}
                                      onChange={onChange}
                                      onFocus={() => {
                                          this.placeholder.current.placeholder = " "
                                      }}
                                      onBlur={() => {
                                          this.placeholder.current.placeholder = placeholder
                                      }}
                                      placeholder={placeholder}/>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

export default TextInput;