import React, {Component} from 'react'
import {translate} from 'react-multi-lang'
import OpvangText from "./OpvangText";
import OpvangTerms from "./OpvangTerms";
import Camera from "./Camera";

class OpvangPage extends Component {

    render() {
        return (
            <div style={this.props.style} className="page-container">
                <OpvangText/>
                <OpvangTerms/>
                <Camera/>
            </div>
        )
    }
}

export default translate(OpvangPage)
