import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {translate} from 'react-multi-lang';
import './App.scss';
import Language from './Language'
import Header from "./components/general/Header";
import Home from "./collections/Home";
import About from "./collections/About";
import Gallery from "./collections/Gallery";
import BitCare from "./collections/BitCare";
import SignUp from "./collections/SignUp";
import Opvang from "./collections/Opvang";
import Contact from "./components/general/Contact";

class App extends Component {


	constructor(props) {
		super(props);
		Language.initialize();
		this.state = {
		};
	}

	render() {
		return (
			<div className="App">
				<Header/>
				<div className="App-wrapper">
				<Switch>
					<Route path="/" exact component={Home}/>
					<Route path="/over" exact component={About}/>
					<Route path="/gallerij" exact component={Gallery}/>
					<Route path="/opvang" exact component={Opvang}/>
					<Route path="/bitcare" exact component={BitCare}/>
					<Route path="/inschrijven" exact component={SignUp}/>
					<Route path="/contact" exact component={Contact}/>
					{/*<Route component={NoMatch}/>*/}
				</Switch>
				</div>
			</div>
		);
	}
}

export default translate(App);
